import ImageList from "./ImageList";
import SelectedImage from "./SelectedImage"
import ImageUpload from "./ImageUpload";
import ImageExternUrl from "./ImageExternUrl";
import { useAtom } from "jotai";
import { currentUserReadOnlyAtom } from "../../services/user/userService";
import { align, tabstate } from "./ImagePicker_types";
import { API_HOST } from "../../utils/constants";
import { useI18n } from "../../utils/lni18n";
import imagePickerService, { imageAtom } from "./ImagePickerService";
import { useEffect } from "react";


export type PickedImageData = {
    imageClasses: string;
    imageId: string | null;
    url: string;
}

export const imageEndPoints = {

    GET_IMAGE_URL: (imageid: string) => `${API_HOST}/image/get/${imageid}`,
    GET_IMAGE_PROXY_URL: (url: string) => `${API_HOST}/image/get/proxy?url=${encodeURIComponent(url)}`
}

export const ImagePicker: React.FC<{ onSubmit: (d: PickedImageData) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();
    const [imageAtomData] = useAtom(imageAtom);
    const { filteredImages, imageData, tab, selectedImage } = imageAtomData;
    const [user] = useAtom(currentUserReadOnlyAtom);


    useEffect(() => {
        imagePickerService.setTab("browse");
    }, [])

    if (!imageData) {
        return null;
    }


    const setTab = (tab: tabstate) => {
        imagePickerService.setTab(tab);
    }

    const menuItems = [

        <a className={tab === "browse" ? "active" : ""} key="browse" onClick={() => setTab("browse")} >
            {t.getText("browse")}
        </a>,


        <a className={tab === "web" ? "active" : ""} key="web" onClick={() => setTab("web")} >
            {t.getText("url")}
        </a>,

        <a className={tab === "upload" ? "active" : ""} key="upload" onClick={() => setTab("upload")} >
            {t.getText("addpict")}
        </a>,

        <a className={(!selectedImage && "off-screen") + " " + (tab === "selected" ? "active" : "")} key="selected" onClick={() => setTab("selected")} >
            {t.getText("insertimage")}
        </a>


    ];



    const setSelectedImage = (id: string) => {
        imagePickerService.setCurrentImage(undefined);
        imagePickerService.setSelectedImage(id);
        if (user) {
            imagePickerService.getImageInfo(id, user.Id);
        }
        imagePickerService.setTab('selected');
    }

    const handleUserChange = (event: any) => {
        imagePickerService.setSelectedUser(event.target.value);
    }

    const onSubmitImage = (data: { imageAlign: align, selectedImage: string | null, selectedUrl: string | null }) => {
        let imageData: PickedImageData = {
            imageClasses: "",
            imageId: data.selectedImage,
            url: ""
        }

        switch (data.imageAlign) {
            case "block":
                imageData.imageClasses = "d-block";
                break;

            case "left":
                imageData.imageClasses = "pull-left";
                break;
            case "right":
                imageData.imageClasses = "pull-right";
                break;

            default:
                break;
        }

        if (data.selectedImage) {
            imageData.url = imageEndPoints.GET_IMAGE_URL(data.selectedImage);
        } else if (data.selectedUrl) {
            imageData.url = imageEndPoints.GET_IMAGE_PROXY_URL(data.selectedUrl);
        }
        else {
            throw new Error("no image at all...!");
        }

        onSubmit(imageData);
    }

    return <div>
        <div className="custom-tab-heading-list">
            {menuItems}
        </div>


        <div className={`mt-4 ${tab !== 'browse' ? "off-screen" : ""}`} >

            <select className="col-12 col-sm-6  form-control" id="classSelect" defaultValue={""} onChange={(e) => handleUserChange(e)}>
                <option key={'all'} value={""}>{t.getText("images.all")}</option>
                <option key={'mine'} value={user?.Id}>{t.getText("images.mine")}</option>
                {imageData.Users.map((u) => {
                    return (
                        <option key={u.Id} value={u.Id}>{u.FullName}</option>
                    )
                })}
            </select>

            <div className="my-4 d-flex ">
                <div className="d-flex mr-5">
                    <div className="badge badge-primary mr-3">&nbsp;</div> {t.getText("images.mine")}
                </div>

                <div className="d-flex">
                    <div className="badge badge-danger mr-3">&nbsp;</div> {t.getText("in.use")}
                </div>
            </div>

            <ImageList user={user!} images={filteredImages} onClick={setSelectedImage} />
        </div>


        <div className={tab !== 'selected' ? "off-screen" : ""}><SelectedImage imagePickerService={imagePickerService} onSubmit={onSubmitImage} /> </div>
        <div className={tab !== 'upload' ? "off-screen" : ""}><ImageUpload imagePickerService={imagePickerService} /></div>
        <div className={tab !== 'web' ? "off-screen" : ""}><ImageExternUrl imagePickerService={imagePickerService} onSubmit={onSubmitImage} /></div>


    </div>
}

export default ImagePicker;
