
export const TestResultStatus = {
    TeacherUrge: 'teacherurge',
    NotSet: 'notset',
    Failed: 'failed',
    Incomplete: 'incomplete',
    Passed: 'passed',
    Unsaved: 'unsaved',
    SavedAtServer: 'savedatserver',
    ApprovedByTeacher: 'approvedbyteacher',
    FollowUpNeeded: 'followupneeded',
    Confirmed: 'confirmed',
    ApprovedWithExcellence: 'approvedwithexcellence',
    NotStarted: 'notstarted',
    RecievedByTeacher: 'recievedbyteacher',
    ApprovedByTeacherButCanbeBetter: 'approvedbyteacherbutcanbebetter',
    UnderReview: 'underreview',
}

export const TestNotReadyStatuses = [ 
    TestResultStatus.NotSet,
    TestResultStatus.Incomplete,
    TestResultStatus.Unsaved,
    TestResultStatus.NotStarted
 ];


export const TestResultStateClasses = {
    'teacherurge': "",
    'notset' : "",
    'failed': "failedState",
    'incomplete': "started",
    'passed': "started",
    'unsaved': "",
    'savedatserver': "",
    'approvedbyteacher': "",
    'followupneeded': "started",
    'confirmed': "started",
    'approvedwithexcellence': "",
    'notstarted': "",
    'recievedbyteacher': "intermediateState",
    'approvedbyteacherbutcanbebetter': "",
    'underreview': "intermediateState"
}