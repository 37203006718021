import { LinkModel, LinksGroupModel } from "../domain/LinksGroupModel";
import { LnCollapseGroup } from "../../components/LnCard/LnCollapseGroup";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { Link } from "./Link";
import { useI18n } from '../../utils/lni18n';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from "../../components/DragHandle/DragHandle";
import classes from "./links.module.scss";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import AddLinkDialog from "./AddLinkDialog";
import coursePartService from "../CoursePartService";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import LnIcon from "src/components/LnIcon";
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";

export default function LinkGroup(props: {
    group: LinksGroupModel, closed?: boolean,
    active?: UniqueIdentifier, dragged?: boolean, partId: string
}) {

    const { group, closed, dragged, active, partId } = props;
    const dialogPortal = useDialog();
    const { languageService: t } = useI18n();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: group.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""

    };

    const dummyLink: LinkModel = {
        Id: "dummy_" + group.Id,
        Url: "",
        Description: ""
    }

    const addOrEditLink = (data?: LinkModel) => {

        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <AddLinkDialog data={data} onCancel={onCancel} onSubmit={onSubmit} />
            },
            size: "md"
        }).then((data: LinkModel) => {
            coursePartService.saveOrUpdateLink(partId, group.Id, data);
        });
    }

    const deleteLink = (id: string) => {

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("link.delete.link.warning")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then((res: boolean) => {
            if (res) {
                coursePartService.deleteLink(partId, group.Id, id);
            }
        });
    }

    const deleteGroup = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <div className="preserve-white" >{t.getText("sure_to_delete")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then((res: boolean) => {
            if (res) {
                coursePartService.deleteLinkGroup({ partId, groupId: group.Id });
            }
        });
    }

    const editGroup = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("edit"),
                    message: <div>{t.getText("name")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                    text: group.Name
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then((name: string) => {
            if (name !== "") {
                coursePartService.renameLinkGroup({ name, groupId: group.Id, partId: partId! })
            }
        });
    }

    const name = <> {group.Name}{group.Links.length > 0 ? <LnIcon name="slides" className="ml-3" /> : <></> }</>

    const actionButtons = <>

        {group.IsDefault == false && <>
            <button className="btn btn-link mx-3" type="button" onClick={editGroup}>
                <LnIcon name="edit" />
            </button>
            <button className="btn btn-link mx-3" type="button" onClick={deleteGroup}>
                <LnIcon name="delete" />
            </button>
        </>
        }
    </>;

    const handle = group.IsDefault ? <div></div> : <div className={classes.dragHandle} {...attributes} {...listeners}><DragHandle /></div>;
    const panel = <LnCollapseGroup actionButton={actionButtons} className={active === group.Id ? classes.active : ""} groupName={name} isOpen={!closed} handle={handle} >
        <SortableContext items={group.Links.map(l => l.Id)} id={group.Id}>
  
            <div className="text-right mb-3">
                <button onClick={() => addOrEditLink()} className={`btn btn-small btn-primary`}>{t.getText("add.link")}</button>
            </div>

            <div>
                {group.Links.map((item) => (
                    <Link deleteLink={deleteLink} edit={(l) => addOrEditLink(l)} active={active} key={item.Id} link={item} />
                ))}
                <Link deleteLink={() => { }} edit={(l) => { }} key={dummyLink.Id} link={dummyLink} dummy={true} />
            </div>
        </SortableContext >
    </LnCollapseGroup>;

    return <>{group.IsDefault ?
        <div id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >{panel}</div>
        :
        <div ref={setNodeRef} style={style} id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >
            {panel}
        </div>}
    </>
}