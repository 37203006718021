import { getDefaultStore, useAtom } from 'jotai';
import { currentUserAtom, currentUserReadOnlyAtom, LoginResult, LoginState, userService } from 'src/services/user/userService';
import { useCallback, useEffect, useState } from 'react';
import { LoginComponent } from 'src/login/LoginComponent';
import { useNavigate } from 'react-router-dom';
import { networkErrorAtom } from 'src/services/api/axiosService';
import { setDialogCloseCallback, useDialog } from 'src/components/Modalservice/Dialogservice';
import { AlertDialog, AlertOptions } from 'src/components/Modalservice/AlertDialog';
import { EmailVerifyDialog } from './EmailVerifyDialog';
import { CurrentUserDto } from 'src/services/user/types';
import { useI18n } from 'src/utils/lni18n';
import { loginService, openLoginAtom } from './LoginService';

const store = getDefaultStore();

const LoginDialog = () => {

    const [networkError, setNetworkError] = useAtom(networkErrorAtom);
    const dialogPortal = useDialog();
    const [openDialog] = useAtom(openLoginAtom);
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    const { languageService: t } = useI18n();


    useEffect(() => {
        if (!openDialog || dialogOpen) return;

        let prevUrl: string | undefined = undefined;
        if (networkError) {
            prevUrl = window.location.pathname;
        }

        navigate("/");
        showLoginDialog(prevUrl);

    }, [openDialog, dialogOpen, networkError])

    useEffect(() => {
        
        const cuUser = store.get(currentUserAtom);

        if (cuUser || dialogOpen) return;

        showLoginDialog();

    }, [])

    const showLoginDialog = (prevUrl?: string) => {
        setDialogOpen(true);

        
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <LoginComponent onCancel={onCancel} onSubmit={onSubmit} />
            },
            size: "md"
        }).then(async (res: LoginResult) => {
            
            if (res.state === LoginState.requireVerification) {
                // show next dialog after closing this
                setDialogCloseCallback( () => showEmailVerifyDialog(res.user!) )
            }
            else if (prevUrl) {
                navigate(prevUrl);
            }
        }).finally(() => {
            setNetworkError(null);
            setDialogOpen(false);
            loginService.setOpenDialog(false);
        });
    }

    const showEmailVerifyDialog = useCallback( async (user?: CurrentUserDto) => {

        const logOut = () => {
            userService.logoutUser(true);
            setNetworkError(null);
        }

        if (!user) {
            logOut();
            return;
        }

        try {
            const emailsent = await dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <EmailVerifyDialog languageService={t} user={user} onCancel={onCancel} onSubmit={onSubmit} />
                },
                size: "md"

            });

            if (emailsent) {
                const res = await userService.sendVerifyEmail(user, 1);
                if (res) {
                    dialogPortal({
                        factory: (onSubmit2) => {
                            const dprops: AlertOptions = {
                                className: "",
                                title: t.getText("dashboard.email.verification.header"),
                                message: <div className="preserve-white" >{res}</div>,
                                languageService: t,
                                onSubmit: onSubmit2
                            }
                            return <AlertDialog {...dprops} />
                        },
                        size: "md"
                    });
                }
            }
        } catch (e) {

        }
        finally {
            logOut();
        }

    }, []);

    return <></>
}


export default LoginDialog;