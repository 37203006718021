import * as React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useI18n } from '../../utils/lni18n';
import { useAtom } from "jotai";
import scrapBookService, { scrapLecturesAtom, scrapScreensAtom } from "./ScrapBookService";
import ScrapScreen from "./ScrapScreen";
import ScrapLecture from "./ScrapLecture";

import classes from "./scrapBook.module.scss";

export enum ScrapType {
    screen,
    lecture
}

export type ScrapBookDialogResult = {
    type: ScrapType,
    id: string
    file?: File;
}

const ScrapBookDialog: React.FC<{ readOnly?: boolean, contextId: string, type: ScrapType, onSubmit: (data: ScrapBookDialogResult) => void, onCancel: () => void }> = ({ readOnly, contextId, type, onSubmit, onCancel }) => {


    const [selected, setSelected] = React.useState<string | undefined>(undefined)

    const { languageService: t } = useI18n();

    const [screens] = useAtom(scrapScreensAtom);
    const [lectures] = useAtom(scrapLecturesAtom);
    const [imageFile, setImageFile] = React.useState<File | undefined>();
    const [imageUrl, setImageUrl] = React.useState<string | undefined>();
    const [imageSelect, setImageSelect] = React.useState<boolean>(false);


    React.useEffect(() => {
        if( type !== ScrapType.screen ) return;

        let img: string | undefined;
        scrapBookService.getImageClip()
            .then(file => {
                if (file) {
                    setImageFile(file);
                    img = URL.createObjectURL(file);
                    setImageUrl(img);
                }
            });

        return () => { if (img) URL.revokeObjectURL(img); }

    }, [type]);


    const handleSubmit = React.useCallback(() => {
        if (selected) {
            let file; 
            if( selected === "img") file = imageFile;

            onSubmit({ type: type, id: selected, file: file });
        }
    }, [imageFile, selected]);

    const screenSelected = (id: string) => {
        scrapBookService.selectScreen(id);
        setImageSelect(false);
        setSelected(id);
    }

    const imageSelected = () => {
        // unselect all screens
        scrapBookService.selectScreen("");
        setSelected( "img" );
        setImageSelect(true);

    }



    const lectureSelected = (id: string) => {
        scrapBookService.selectLecture(id);
        setSelected(id);
    }

    const clearItems = () => {
        switch (type) {
            case ScrapType.screen:
                scrapBookService.clearscreens();
                break;

            case ScrapType.lecture:
                scrapBookService.clearLectures();
                break;
        }
    }



    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("scrapbook")}</ModalHeader>
        </span>
        <ModalBody>
            <div className="d-flex flex-wrap">
                {type === ScrapType.screen &&
                    screens.map(s => <ScrapScreen disabled={readOnly || contextId === s.LectureId} key={s.ScreenId} screen={s} select={(s: string) => screenSelected(s)} />)
                }

                {type === ScrapType.lecture &&
                    lectures.map(l => <ScrapLecture disabled={readOnly || contextId === l.PartId} key={l.Id} lecture={l} select={(s: string) => lectureSelected(s)} />)
                }

            </div>

            {imageUrl && <div className="row border-top border-bottom mt-3">
                
                <div className="col-6 py-4" onClick={imageSelected}>
                    <img src={imageUrl} className={`img-max-100 ${ classes.clipImage } ${ imageSelect ? classes.selected : "" } ` } />
                </div>
                <div className="col-6 py-4 preserve-white">
                    {t.getText("image.from.clipboard")}
                </div>
            </div>
            }

            <div className="mt-3">
                <button className="btn btn-warning btn-small" onClick={clearItems}>{t.getText("clear")}</button>
            </div>
        </ModalBody>
        <ModalFooter>

            <Button disabled={readOnly || !selected} className="btn btn-primary btn-small" onClick={() => handleSubmit()}>{t.getText("paste.clipboard")}</Button>&nbsp;
            <Button className="btn btn-inverse btn-small" onClick={onCancel}>{t.getText("close")}</Button>
        </ModalFooter>
    </>
}

export default ScrapBookDialog;
