import { atom, getDefaultStore, useAtom } from 'jotai';
import { currentUserAtom, currentUserReadOnlyAtom } from 'src/services/user/userService';


const store = getDefaultStore();
export const openLoginAtom = atom<boolean>(false);


export class LoginService {

    constructor() {
        // subscribe to changes in current user
        store.sub(currentUserAtom, () => {
            const currentUser = store.get(currentUserReadOnlyAtom);
            store.set(openLoginAtom,currentUser === null)
        })
    }

    public setOpenDialog(state: boolean){
        store.set(openLoginAtom, state);
    }


}

export const loginService = new LoginService();

