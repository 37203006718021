import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CurrentUserDto } from "src/services/user/types";
import { LanguageService } from "src/utils/languageService";

export interface EmailVerifyDialogOptions {
    languageService: LanguageService;
    onSubmit: (d: boolean) => void;
    onCancel: () => void;
    user: CurrentUserDto;
}

export const EmailVerifyDialog: React.FC<EmailVerifyDialogOptions> = ({
    onSubmit,
    onCancel,
    languageService,
    user
}) => {

    const t = languageService;
 
    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("dashboard.email.verification.header")}</ModalHeader>
        </span>
        <ModalBody>

            <div className="preserve-white" dangerouslySetInnerHTML={{ __html: languageService.getText("dashboard.email.verification.message.email", "<h5>" + user.Email + "</h5>") }}></div>

        </ModalBody>
        <ModalFooter>
            <button type="button" className="btn btn-primary" onClick={()=>onSubmit(true)} >{t.getText("send.verification.email")}</button>&nbsp;
            <Button type="button" className="btn btn-warning" onClick={() => onCancel()}>{t.getText("close")}</Button>
        </ModalFooter>
    </>
};
