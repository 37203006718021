import React, {  useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useI18n } from "../utils/lni18n";
import LanguageMenu from "../components/LanguageMenu/LanguageMenu";
import LoginForm from "./LoginForm";

import classes from "./Login.module.scss";
import SendPasswdEmail from "./SendPasswdEmail";
import { useAtom } from "jotai";
import { networkErrorAtom } from "src/services/api/axiosService";
import { LoginResult } from "src/services/user/userService";

export const LoginComponent: React.FC<{ onSubmit: (res: LoginResult) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const [networkError] = useAtom(networkErrorAtom);
    const [activeTab, setActiveTab] = useState('login');
    const { languageService: t } = useI18n();

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div id="loginDialog"  className={classes.loginbox} >

            <div className="d-flex align-items-center " >
                <div className="w-50 moveable" data-dialog-draggable >
                    <img src="/imgs/logo.svg" draggable={false} />
                </div>

                <div className="ml-auto">
                    <LanguageMenu caretClass="dark-caret" container="#loginDialog" />
                </div>
            </div>

            <div className="my-5">
                <h2 className="d-flex">
                    LäraNära Author Login
                </h2>

                {networkError &&
                    <div className="mt-3 alert alert-danger" dangerouslySetInnerHTML={{ __html: networkError.message }} ></div>
                }

            </div>





            <Nav tabs className="nav-justified">
                <NavItem>
                    <NavLink onClick={() => { toggle('login'); }}
                        className={activeTab === 'login' ? 'active ' : ''} >
                        {t.getText("login")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        onClick={() => { toggle('sendpw'); }}
                        className={activeTab === 'sendpw' ? 'active ' : ''} >
                        <div className="">{t.getText("password.forgot")}</div>

                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="login">
                    <div className="border border-top-0 p-5">
                        <LoginForm onSubmit={onSubmit} onCancel={onCancel} />
                    </div>
                </TabPane>
                <TabPane tabId="sendpw">
                    <div className="border border-top-0 p-5">
                        <SendPasswdEmail />
                    </div>
                </TabPane>
            </TabContent>

        </div>
    )

}