//@ts-check
import { StringFormat } from "./StringFormat";
import api from "../services/api/axiosService";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import localeData from "dayjs/plugin/localeData";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import { atom, getDefaultStore } from "jotai";
import { API_URL, flagUrls } from "./constants";

export interface TextState {
    languages: LanguageDto[],
    currentLang: LanguageDto
}
export interface LanguageDto {
    Id: number;
    Name: string;
    DisplayName: string;
    Short: string;
    flag: string;  // url to flag image. Only set and used in client
    IsCurrent: boolean;
    StaticText: string;
}

const defaultLang: LanguageDto = {
    Id: 0,
    Name: "sv-SE",
    DisplayName: "Svenska",
    Short: "sv",
    flag: "",
    IsCurrent: true,
    StaticText: ""

}
const defaulttext: TextState = { languages: [], currentLang: defaultLang };

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);

export const languagesAtom = atom<TextState>(defaulttext);
languagesAtom.debugLabel = "languagesAtom";
const textsAtom = atom<Record<string, string>>({});
textsAtom.debugLabel = "textsAtom";
const store = getDefaultStore();

export const langAtom = atom<TextState>((get) => get(languagesAtom));
langAtom.debugLabel = "langAtom";

export const textReadyAtom = atom<boolean>(false);
textReadyAtom.debugLabel = "textReadyAtom";

const endPoints = {
    API_URL: API_URL,
    LANGUAGES_URL: '/languages',
    SET_LANG_URL: '/language',
    GET_TEXT_DICTIONARY: (lang: number) => `/languages/authorstrings/${lang}`,
}


export class LanguageService {

    constructor() {
        this.init();
    }

    dictSelector = () => { return {} };

    private init() {
        this.getLanguages().then(id => {
            this.loadStrings(id);
        });
    }

    get currentLanguage() {
        return store.get(languagesAtom).currentLang;
    }
    set currentLanguage(value) {
        const a = store.get(languagesAtom);
        a.currentLang = value;
        store.set(languagesAtom, a);
    }

    languagesSelector = () => { return [] };

    locale = "en";



    handleChange = () => {
        if (this.currentLanguage && this.locale === this.currentLanguage.Short) {
            return;
        }
        if (this.currentLanguage) {
            this.locale = this.currentLanguage.Short;
            dayjs.locale(this.currentLanguage.Short);
        }
    }

    // registerStore = store => {
    //     this.dictSelector = () => store.getState().text.text;
    //     this.currentLangSelector = () => store.getState().text.currentLang;
    //     this.languagesSelector = () => store.getState().text.languages;

    //     store.subscribe(this.handleChange);
    //     const lang = this.currentLangSelector();
    //     if (lang) {
    //         this.locale = lang.Short;
    //         dayjs.locale(lang.Short);
    //     }
    // }

    haveKey = (key: string) => {
        return !!store.get(textsAtom)[key];
    }

    getText = (key: string, ...ags: string[]) => {

        const dict = store.get(textsAtom);
        if (key && dict && dict[key]) {
            return StringFormat(dict[key], ags);
        }
        if (import.meta.env.MODE !== "production") {
            console.info(`missing text key: ${key}`)
        }
        return key || "";
    };

    getTextFirstToUpper = (key: string, ...ags: any) => {

        const s = this.getText(key, ags);
        if (!s) {
            return "";
        }

        return s.substr(0, 1).toUpperCase() + s.substr(1);
    };

    localeUtils = {
        formatDay: (d: Date) => {
            this.getFullDateString(d);
        },
        formatMonthTitle: (d: Date | string) => {
            dayjs(d).format("MMMM");
        },
        formatWeekdayShort: (d: Date | string) => {
            dayjs(d).format("dd");
        },
        formatWeekdayLong: (d: Date | string) => {
            dayjs(d).format("dddd");
        },
        getFirstDayOfWeek: (d: Date | string) => {
            // @ts-ignore
            dayjs.localeData().firstDayOfWeek();
        }
    };



    get languages() {
        return store.get(languagesAtom)
    }

    getShortDateString = (date: Date) => {

        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('L');
    }

    getShortDateTimeString = (date: Date) => {
        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('L LT');
    }

    getTimeString = (date: Date) => {
        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('LT');
    }

    getFullDateTimeString = (date: Date) => {
        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('LLL');

    }

    getFullDateString = (date: Date) => {
        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('LL');
    }

    getMediumDateString = (date: Date | string) => {
        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('ll');
    }

    getMediumDateTimeString = (date: Date) => {
        const djs = this.makeValidDayJs(date);
        if (!djs) { return null; }
        return djs.format('lll');
    }

    getTimeSpanString = (from: Date, to: Date, format: string) => {

        const start = dayjs(from);
        const end = dayjs(to);
        if (start.isSame(end, "date")) {
            const f = format.replace("HH:mm", "");
            return `${start.format(f)}  ${start.format("HH:mm")} - ${end.format("HH:mm")}`;
        }
        else {
            return `${start.format(format)} - ${end.format(format)}`;
        }

    }


    gethhmmss = (s: number, hideZeroMinutes?: boolean, showZeroHours?: boolean) => {
        let secs = Math.round(s);
        let minutes = Math.floor(secs / 60);
        secs = secs % 60;
        let hours = Math.floor(minutes / 60)
        minutes = minutes % 60;
        if (hours === 0 && !showZeroHours) {
            if (hideZeroMinutes && minutes === 0) {
                return `${this.pad(secs)}`
            }
            else {
                return `${this.pad(minutes)}:${this.pad(secs)}`
            }
        }
        return `${hours}:${this.pad(minutes)}:${this.pad(secs)}`;
    }

    private pad(num: number) {
        return ("0" + num).slice(-2);
    }


    private makeValidDayJs = (d: Date | string) => {

        if (!d) {
            return null;
        }

        try {
            const dayj = dayjs(d);
            if (dayj.isValid()) {
                return dayj;
            }
        }
        catch { }

        return null;
    };


    localeUses24HourTime = (locale: string) => {
        locale = locale || this.locale || "en";

        const format = new Intl.DateTimeFormat(locale, {
            hour: 'numeric'
        });

        const d = format.formatToParts(new Date(2020, 0, 1, 15)).find(part => part.type === 'hour')
        if (d && d.value) {
            return parseInt(d.value, 10) > 10;
        }
        return true;
    };

    async getLanguages() {
        try {
            const response = await fetch(`${API_URL}/${endPoints.LANGUAGES_URL}`, {  credentials: 'include' });
            if (response) {
                const data: LanguageDto[] = await response.json();
                let langs = data.map(l => {
                    return { ...l, flag: flagUrls[l.Name] }
                });

                let current = langs.find((i) => i.IsCurrent === true)
                    || langs[0];

                store.set(languagesAtom, { languages: langs, currentLang: current });
                dayjs.locale(current.Short);
                return current.Id;
            }
            else {
                return 0
            }

        }
        catch (error) {
            console.error(error);
            throw error;
        };


    }


    async loadStrings(langid: number) {

        try {
            const response = await api.get<Record<string, string>>(endPoints.GET_TEXT_DICTIONARY(langid))
            if (response) {
                store.set(textsAtom, response.data);
                store.set(textReadyAtom, true);

                const langs = store.get(languagesAtom);
                if (langs) {
                    const current = langs!.languages.find(l => l.Id === langid);
                    if (current) {
                        store.set(languagesAtom, { languages: langs?.languages, currentLang: current });
                        dayjs.locale(current.Short);
                    }
                }

                store.set(textReadyAtom, true);
            }
            return true;
        }
        catch (error) {
            console.error(error);
            throw error;
        };

    }

    async changeLang(id: number) {
        await api.put(endPoints.SET_LANG_URL, { Value: id });
        this.loadStrings(id);
    }


}






const languageService = new LanguageService();

export default languageService;