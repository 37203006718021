import { Card, CardBody, CardHeader } from "reactstrap";
import { useI18n } from "src/utils/lni18n";
import classes from "./home.module.scss"
import { useAtom } from "jotai";
import { textReadyAtom } from "src/utils/languageService";
import { currentUserAtom } from "src/services/user/userService";
import WaitRipple from "src/components/WaitRipple";

const Home = () => {

  const { languageService: t } = useI18n();
  const [textReady] = useAtom(textReadyAtom);
  const [currentUser]= useAtom(currentUserAtom);

  return (

    <div className="h-100 max-col-width m-auto">


      <div className="scrollContent h-100" aria-live="polite">
        <Card className={classes.logoCard + " big-card-box"}>
          <CardBody >
            <div className="text-center">
              <img className="m-auto" src="/imgs/logo-author.svg" />
            </div>

          </CardBody>

        </Card>

        {!textReady && !currentUser &&  <Card className="big-card-box mt-5">
          <CardBody >
            <div className="text-center">
              <WaitRipple />
              Startar... Starting... Starter...
            </div>

          </CardBody>

        </Card> }


        {textReady &&
          <div className={`row row-cols-1 row-cols-md-2 ${classes.smallCards}`}>
            <div className="col mb-4">
              <Card className="h-100">
                <CardHeader>
                  <h2>{t.getText("welcome")}</h2>
                </CardHeader>
                <CardBody>
                  <div className="preserve-white">{t.getText("welcome.author")}</div>
                </CardBody>

              </Card>
            </div>

            <div className="col mb-4">
              <Card className="h-100">
                <CardHeader>
                  <h2>{t.getText("help")}</h2>
                </CardHeader>
                <CardBody>
                  Videos will come
                </CardBody>

              </Card>
            </div>

          </div>
        }
      </div>

    </div>


  )
}

export default Home;
