import { atom, getDefaultStore, useAtom } from 'jotai';
import { AxiosError } from 'axios';
import { CurrentUserDto, LoginFormDto, LoginPingDto, LoginReplyDto, LoginResetReplyDto, LogoutResult, SwitchUserResult, UserMailDto } from './types';
import api from '../api/axiosService';
import { API_URL } from '../../utils/constants';
import testService from 'src/coursepart/Test/TestService';
import { v4 } from 'uuid';
import languageService from 'src/utils/languageService';
import coursePartService from 'src/coursepart/CoursePartService';
import { pingEndPoints } from 'src/components/Pinger/Pinger';
import courseService from 'src/course/CourseService';
import coursePartCollectionService from 'src/coursepart/CoursePartCollectionService';
import courseCollectionService from 'src/course/CourseCollectionService';
import * as Sentry from "@sentry/react";
import { release } from "../../utils/constants";

export const userEndPoints = {
  GET_USER_DATA_URL: (userid: string) => `/user/${userid}/detail`,
  LOGIN_URL: '/login/login',
  LOGOUT_URL: '/login/logout',
  SENDPW_URL: '/login/resetpassword',
  CHECK_FEDERATION_URL: (email: string) => `/login/CheckForFederation/${email}`,
  GET_USER_FIND_URL: (text: string, param: string, value: string) => `/user/find/?text=${text}&${param}=${value}`,
  GET_USER_SWITCH_URL: `/login/switch`,  // use w POST

  GET_USER_EMAIL_VERIFY_URL: (userId:string, index:number,) => `/user/${userId}/detail/email/${index}/SendVerification`,

  GET_USER_IMAGE_URL: (id: string) => {
    const x: string = v4();
    return `${API_URL}/user/${id}/image?x=${x}`
  },
}

export enum LoginState{
    ok= 0,
    fail=1,
    requireVerification = 2,
    redirect = 3
}

export type LoginResult = {
    state: LoginState,
    user?: CurrentUserDto
};

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$/;

export const currentUserAtom = atom<CurrentUserDto | null>(null);

const loadingAtom = atom<boolean>(false);
export const userErrorAtom = atom<{ message: string } | null>(null);

export const currentUserReadOnlyAtom = atom((get) => get(currentUserAtom))
currentUserAtom.debugLabel = "currentUserAtom";
loadingAtom.debugLabel = "user loadingAtom";
userErrorAtom.debugLabel = "user errorAtom";

export class UserService {



  public async sendPwEmail(name: string) {
    const response = await api.post<LoginResetReplyDto>(userEndPoints.SENDPW_URL, { Value: name });
    if (response && response.data) {
      return response.data;
    }
  }

  store = getDefaultStore();
  constructor() {
    const usJson = window.localStorage.getItem("currentUser");
    if (usJson) {

      this.store.set(currentUserAtom, JSON.parse(usJson));

      // so we have data from local storage. Let's check if we're really logged in.
      this.testPing()
        .then(result => {
          // testService.getTestTypes();
        })
        .catch(() => {
          this.store.set(currentUserAtom, null);
        })

    }
    else {
      this.store.set(currentUserAtom, null);
    }

  }



  private async testPing() {

    let postData = { ReleaseId: release };
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${API_URL}${pingEndPoints.PING_URL}`, { headers: headers,   body: JSON.stringify( postData), credentials: "include", method: "POST" });
    if (response && response.status == 200) {
      const data: LoginPingDto = await response.json();
      if (data.IsLoggedIn) return true;
    }
    return false;
  }

  public async findUsers(text: string, param: string, value: any) {
    const response = await api.get<UserMailDto[]>(userEndPoints.GET_USER_FIND_URL(text, param, value));
    if (response.status === 200) {
      return response.data;
    }
  }

  
  public async logoutUser(force?: boolean) {

    const coursesDirty = courseService.areCoursesDirty();
    const partsDirty = coursePartService.partsDirty();
    if (!force && (coursesDirty || partsDirty)) {
      return { course: coursesDirty, parts: partsDirty?.Id };
    }


    await this.clearUserData();

    await api.post<LogoutResult>(userEndPoints.LOGOUT_URL);
    window.localStorage.removeItem("currentUser");
    this.store.set(currentUserAtom, null);

    Sentry.addBreadcrumb({
      category: "auth",
      message: "user logged out",
      level: 'info',
    });

    return undefined;

  }

  private async clearUserData() {
    courseService.closeAllCourses(true);
    coursePartCollectionService.clearCollection();
    courseCollectionService.clearCollection();
    await coursePartService.closeAllParts(true);
  }

  public userImageUrl() {



  }



  public async loginUser(loginData: LoginFormDto): Promise<LoginResult> {
    try {
      this.store.set(loadingAtom, true);

      loginData.FinalDomain = location.hostname.toLowerCase().replace(".laranara.se", "");

      const response = await api.post<LoginReplyDto>(userEndPoints.LOGIN_URL, loginData);
      if (response.status === 200) {

        if (response.data.RedirectUrl) {
          window.location.href = response.data.RedirectUrl;
          return { state : LoginState.redirect};
        }

        if (response.data.CurrentUser === null) {
          if (response.data.Message)
            this.store.set(userErrorAtom, { message: response.data.Message });
          else
            this.store.set(userErrorAtom, { message: languageService.getText("wronglogin") });

            return { state: LoginState.fail};
        }
        else {

            if( response.data.CurrentUser.IsVerified === false ){
                return { state: LoginState.requireVerification, user: response.data.CurrentUser};
            }

          this.store.set(currentUserAtom, response.data.CurrentUser);
          this.store.set(userErrorAtom, null);
          testService.getTestTypes();

          window.localStorage.setItem("currentUser", JSON.stringify(response.data.CurrentUser));

          coursePartService.userLoggedIn( response.data.CurrentUser.Id );
          courseService.userLoggedIn( response.data.CurrentUser.Id );

          Sentry.addBreadcrumb({
            category: "auth",
            message: "user login: " + response.data.CurrentUser.Id,
            level: 'info'
          });

          Sentry.setUser({ id: response.data.CurrentUser.Id });

          return { state: LoginState.ok, user: response.data.CurrentUser};
        }

      } else {
        this.store.set(userErrorAtom, { message: `Request failed with status code ${response.status}` });
        return { state: LoginState.fail};
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      this.store.set(userErrorAtom, { message: axiosError.message });
      return { state: LoginState.fail};
    } finally {
      this.store.set(loadingAtom, false);
    }
  };


  public async checkFederation(email: string) {
    const okEmail = emailPattern.test(email);
    if (!okEmail) return { result: false, text: "" };

    const response = await api.get<{ result: boolean, text: string }>(userEndPoints.CHECK_FEDERATION_URL(email));
    if (response.status === 200) {
      return response.data;
    }

    return { result: false, text: "" };
  }

  public async switchUser(id: string) {

    const response = await api.post<SwitchUserResult>(userEndPoints.GET_USER_SWITCH_URL, { UserId: id, Shadow: false });
    if (response.status === 200) {
      const currentUser = this.store.get(currentUserAtom);
      const currentUserId = currentUser?.Id;
      Sentry.addBreadcrumb({
        category: "auth",
        message: `switch user ${currentUserId} => ${response.data.CurrentUser}`,
        level: 'info'
    });

      this.clearUserData();
      this.store.set(currentUserAtom, response.data.CurrentUser);
      this.store.set(userErrorAtom, null);

      coursePartService.userLoggedIn( response.data.CurrentUser.Id );
      courseService.userLoggedIn( response.data.CurrentUser.Id );


      window.localStorage.setItem("currentUser", JSON.stringify(response.data.CurrentUser));
      return true;
    }

    return false;

  }

  public async sendVerifyEmail(user: CurrentUserDto, type: number) {

    const response = await api.post<{Value:string}>(userEndPoints.GET_USER_EMAIL_VERIFY_URL(user.Id, type), null);
    if (response.status === 200) {
        return response.data.Value
    };
    return null;
  }

}

export const userService = new UserService();


export const useCurrentUser = () => {

  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [error, setError] = useAtom(userErrorAtom);

  const fetchCurrentUser = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.get(userEndPoints.GET_USER_DATA_URL(id));
      if (response.status === 200) {
        setCurrentUser(response.data);
        setError(null);
      } else {
        setError({ message: `Request failed with status code ${response.status}` });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      setError({ message: axiosError.message });
    } finally {
      setLoading(false);
    }
  };

  return { currentUser, loading, error, fetchCurrentUser };
};
