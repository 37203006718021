
import { CardBody, CardFooter, CardTitle } from "reactstrap";
import { LectureDtoWithOriginals } from "../domain/LectureDtoWithOriginals";
import { FormatTimeDuration } from "../../utils/DateTimeFormat";
import { useI18n } from "../../utils/lni18n";
import { CSS } from '@dnd-kit/utilities';
import classes from './lecture.module.scss';
import LnIcon from '../../components/LnIcon';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from "@dnd-kit/core";
import { DragHandle } from "../../components/DragHandle/DragHandle";
import coursePartService, { onePartatom } from "../CoursePartService";
import {  useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useAtom } from "jotai";
import ReadOnlyLectureLink from "../ReadOnly/Lecture/ReadOnlyLectureLink";


const LectureLink = (props: { onContextMenu: React.MouseEventHandler<HTMLElement>, partId: string, lecture: LectureDtoWithOriginals, dragged?: boolean, active?: UniqueIdentifier }) => {

    const { languageService: t } = useI18n();
    const { lecture: l, active, dragged, partId, onContextMenu } = props;

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const part = partData.Data;
    const navigate = useNavigate();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: l.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if( e.ctrlKey) return;
        coursePartService.selectLecture(partId, l);
    }

    const handleDoubleClick = () => {
        coursePartService.selectLecture(partId, l, true);
        navigate(l.Id)
    }

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        coursePartService.selectLecture(partId, l, true);
        onContextMenu(e);
    }

    if (part && part.Locked) return <ReadOnlyLectureLink onContextMenu={onContextMenu} partId={partId} lecture={l} />

    return (
        <div onContextMenu={handleContextMenu} onDoubleClick={ handleDoubleClick} onClick={handleClick} style={style} ref={setNodeRef} key={l.Id} role="listitem"
            className={[(l.Selected ? classes.selectedLecture : ""), (active === l.Id ? classes.active : ""), classes.card, 'card pointer'].join(' ')} >


            <CardBody className={`p-3 ${classes.cardBody}`}>
                <CardTitle className={classes['card-title']}>
                    <div className={classes.nameDiv}>
                        <div className={classes.dragHandle} {...attributes} {...listeners}><DragHandle /></div>
                        {l.Name}
                    </div>

                </CardTitle>
                <div className={classes['img-wrapper']}>
                    <img className={classes['lecture-img']} src={l.ImageUrl} alt={l.Name} />
                </div>



            </CardBody>

            <CardFooter className={classes['card-footer']}>
                <span><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="slides" />
                    &nbsp;{l.NumOfScreens} <span className="d-none d-sm-inline">{t.getText('lecture.screens')}</span></span>
                {
                    FormatTimeDuration(l.Duration, t) &&
                    <span className="ml-1"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDuration(l.Duration, t)}</span>
                }
            </CardFooter>
        </div>
    );

}


export default LectureLink;