import classes from "./ImagePicker.module.scss";
import { ImagePickerImageDto } from "./ImagePicker_types";
import { API_HOST } from "../../utils/constants";
import { CurrentUserDto } from "src/services/user/types";


const GET_IMAGE_THUMB_URL = (imageid:string) => `${API_HOST}/image/get/${imageid}/thumbnail`;

const ImageList = ( props: { user: CurrentUserDto, images?: ImagePickerImageDto[], onClick:Function}) => {

  const {images, onClick,user } = props;

    return  <div className={ classes.imgList} >
    {images && images.map(i => {
      return <div key={i.ImageId} onClick={()=>onClick( i.ImageId )} className={"m-2 pointer " + classes.thumbBox} id={"img" + i.ImageId}>

        <img src={GET_IMAGE_THUMB_URL(i.ImageId)} alt="" />
        { i.User.Id === user.Id && <div className="green-mark-right small-mark"></div> }
        { i.HaveReferences  && <div className="red-mark-left small-mark"></div> }
      </div>
    })}
  </div>
}

export default ImageList;
