import { useCurrentUser } from 'src/services/user/userService'
import UserMenu from './UserMenu';
import classes from './TopMenu.module.scss';
import { useAtom } from 'jotai';
import WaitRipple from '../WaitRipple';
import CoursePartMenu from './CoursePartMenu';
import LnIcon from '../LnIcon';
import CourseMenu from './CourseMenu';
import { waitRippleAtom } from 'src/services/WaitRippleService';
import { textReadyAtom } from 'src/utils/languageService';
import LanguageMenu from '../LanguageMenu/LanguageMenu';
import { feedbackIntegration } from '@sentry/react';
import { useEffect, useRef } from 'react';
import { useI18n } from 'src/utils/lni18n';
import { loginService } from 'src/login/LoginService';


let feedbackButtonElementIsSet = false;

export default function TopMenu(props: { setOpenSideBar: (state: boolean) => void }) {
    const { currentUser } = useCurrentUser();
    const { languageService: t } = useI18n();
    const feedbackButton = useRef<HTMLButtonElement | null >(null);

    const [waitRipple] = useAtom(waitRippleAtom);
    const [textReady] = useAtom(textReadyAtom);


    useEffect(() => {

        if (!waitRipple && feedbackButton.current && feedbackButtonElementIsSet === false) {
            feedbackButtonElementIsSet = true;
            const feedback = feedbackIntegration({
                 // Additional SDK configuration goes in here, for example:
                 colorScheme: "system",
                 showName: false,
                 showEmail: false,
                 triggerLabel: "Rapportera ett fel",
                 formTitle: "Rapportera ett fel",
                 submitButtonLabel: "Skicka in rapport",
                 cancelButtonLabel: "Avbryt",
                 addScreenshotButtonLabel: "Lägg till en skärmdump",
                 removeScreenshotButtonLabel: "Ta bort skärmdump",
                 messageLabel: "Beskriv felet",
                 messagePlaceholder: "Vad gick fel? Vad förväntade du dig",
                 successMessageText: "Tack för din medverkan",
                 autoInject: false,
                 isRequiredLabel: "(obligatorisk)"
            });

            feedback.attachTo(feedbackButton.current);

        }
    }, [waitRipple, feedbackButton.current])


    return (
        <>
            <div className={`ml-5 p-2 flex gap-2 text-lg d-flex align-items-center ${classes.topmenu}`}>
                {waitRipple && <WaitRipple />}

                <div className='toggle'>
                    <button className='btn btn-link p-3' onClick={() => props.setOpenSideBar(true)}>
                        <LnIcon name="next-2" className='icon-small' />
                    </button>
                </div>

                {textReady && currentUser === null && <button type='button' onClick={()=>loginService.setOpenDialog(true)}  className='mt-3 btn btn-small btn-warning'>{t.getText("login")}</button>}

                {textReady && currentUser && <>
                    <div className="d-flex flex-wrap" >
                        <div className='mr-3'>
                            <CoursePartMenu />
                        </div>
                        <CourseMenu />
                    </div>

                    

                    <div className="ml-auto d-flex pr-4" >
                    <button ref={feedbackButton} className='btn btn-warning btn-small mr-3 mt-3'>Rapportera ett fel</button>
                        <div className="mr-5 mt-2">
                            <LanguageMenu caretClass={"white-caret"} />
                        </div>
                        <UserMenu />


                    </div>
                </>}

            </div>


        </>
    )
}
