import { useEffect, useRef, useCallback, ChangeEvent, useMemo, useState } from "react";
import classes from "./Screen.module.scss";
import ReactPlayer from 'react-player/file'
import lecturePlayerService from "../Player/LecturePlayerService";
import { EditScreenDto, LectureState, ScreenType } from "../domain/LectureState";
import { OnProgressProps } from "react-player/base";
import HtmlTextEditor from "src/components/TextEditor/HtmlTextEditor";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { onePartatom } from "src/coursepart/CoursePartService";


export const ImageSoundPlayer = (props: { currentScreen: EditScreenDto, lecturedata: LectureState, className: string, onReady: () => void }) => {

    const editorSettingsHtml: any = {
        toolbar: "undo redo | styleselect | table | bold italic | alignleft aligncenter  | bullist numlist outdent indent | image |  link code charmap ",
    }

    const editorSettingsTranscript: any = {
        toolbar: "undo redo | bold italic | charmap ",
    }

    const { showTranscript, currentLecture, doPlay, volume, playbackRate } = props.lecturedata;
    //const { currentScreen } = props;

    const { AudioUrl, ImageUrl, ScreenId, Html, EditHtml, Transcript, ScreenType: screentype } = props.currentScreen;

    const {state} = useLocation();

    const { partId, playtype } = useParams();


    const [partData] = useAtom(
        useMemo(() => {
            return onePartatom(partId!);
        }, [currentLecture]));

    const readOnly = !!partData.Data?.Locked || playtype === "view";

    const transcriptValue = useRef<string | undefined>(undefined);
    const htmlValue = useRef<string | undefined>(undefined);
    const [init, setInit] = useState<boolean>(false);



    const mediaElement = useRef<any>(undefined);
    const imgWrap = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const htmlTextWrap = useRef<HTMLDivElement>(null);


    useEffect(() => {
        setInit(true);
    }, [props.currentScreen.ScreenId]);

    const handleReady = () => {
        transcriptValue.current = Transcript || undefined;
        htmlValue.current = Html || undefined;
        setInit(false);
        if (props.onReady) props.onReady();
    }

    const handleProgress = (e: OnProgressProps) => {
        lecturePlayerService.setPlayedPercent(e.played * 100);
    }

    const handleMediaAtEnd = async () => {
        await handleMediaPause();
    }

    const handleMediaPause = async () => {
        lecturePlayerService.setPlayerRunningState(false);
        //lecturePlayerService.setWantedPlayingState(false);
    }

    useEffect(() => {
        adjustImageAndEditors();
    }, [EditHtml])



    const handlePlay = () => {
        lecturePlayerService.setPlayerRunningState(true);
    }

    const seekTo = async (fraction: number, screenType: number) => {
        if (screenType !== ScreenType.ImageSound) {
            return;
        }

        if (mediaElement.current) {
            mediaElement.current.seekTo(fraction);
        }
    };

    const setHtmlValue = useCallback((name: string, html: string, state?: boolean, text?: string) => {

        if (name !== "transcript" && name !== "htmlScreen") {
            throw new Error('name must be "transcript" or "htmlScreen"');
        }

        lecturePlayerService.UpdateHtmlInCurrentScreen(currentLecture.LectureId, html, name)
        adjustImageAndEditors();

    }, [ScreenId, currentLecture.LectureId]);


    const adjustImageAndEditors = useCallback((e?: any) => {
        let h = imgWrap.current!.offsetHeight;
        let w = imgWrap.current!.offsetWidth;
        if (htmlTextWrap.current) {
            h -= htmlTextWrap.current.offsetHeight;
        }
        imageRef.current!.style.maxHeight = h + "px";
        imageRef.current!.style.maxWidth = w + "px";

    }, [imgWrap, htmlTextWrap, imageRef]);

    const onEditorClick = (e: React.MouseEvent<HTMLDivElement>) => {

        e.stopPropagation();
        adjustImageAndEditors();
    }

    const toggleTranscript = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        lecturePlayerService.toggleTranscriptEdit();
    }

    const htmlUpdated = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setHtmlValue(e.currentTarget.name, e.currentTarget.value);
    }

    useEffect(() => {
        if (props.currentScreen.ScreenType !== ScreenType.Video) {
            adjustImageAndEditors();
        }
    }, [adjustImageAndEditors])

    useEffect(() => {
        lecturePlayerService.RegisterScreen({ seekTo: seekTo });
        window.addEventListener("resize", adjustImageAndEditors);
        return () => window.removeEventListener("resize", adjustImageAndEditors);
    }, []);

    return <div className={[props.className, classes.imgSoundPlayer, "w-100 h-100", (showTranscript && classes.withTranscript)].join(" ")}>

        <div className="d-flex flex-row w-100 h-100 align-items-center overflow-hidden" ref={imgWrap}>
            <div className="d-flex w-100 flex-column align-items-center">
                <img ref={imageRef} alt="" onLoad={() => handleReady()} draggable="false" src={ImageUrl} className={(EditHtml ? classes.screenImgWithHtml : classes.screenImg)} />
                {(EditHtml || htmlValue.current) !== undefined &&
                    <div ref={htmlTextWrap} className={classes.htmlForScreen} >

                        {!readOnly && !init &&
                            <HtmlTextEditor value={Html} onChange={htmlUpdated} editorSettings={editorSettingsHtml} onClick={onEditorClick}
                                initialValue={htmlValue.current} name="htmlScreen"  ></HtmlTextEditor>
                        }
                        { readOnly && <div dangerouslySetInnerHTML={{__html: Html}}></div>}
                    </div>

                }
            </div>

            <div className="flex-fill"></div>
        </div>


        {(transcriptValue.current || showTranscript) && <div className={classes.dTranscript + " card mt-2 ml-3 mr-2"}>
            <div className="card-header d-flex justify-content-end screenTranscript">

                <button onClick={toggleTranscript} type="button" className="close round" aria-label="Close">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div className={classes.transcriptEdit}>
                {!readOnly &&!init &&
                    <HtmlTextEditor value={Transcript} onChange={htmlUpdated} className="h-100"
                        editorSettings={editorSettingsTranscript} onClick={onEditorClick}
                        initialValue={transcriptValue.current} name="transcript"  ></HtmlTextEditor>
                }
                { readOnly && <div className="m-3" dangerouslySetInnerHTML={{__html: Transcript}}></div>}
            </div>


        </div>}
        <div className={"w-100 h-100 d-flex align-items-center off-screen"}>
            <ReactPlayer onEnded={handleMediaAtEnd}
                width="10"
                height="10"

                onPause={handleMediaPause}
                onPlay={handlePlay}
                onProgress={handleProgress}
                progressInterval={100}
                playing={!!AudioUrl && screentype !== ScreenType.Video && doPlay}
                controls={false}
                volume={volume / 100}
                url={AudioUrl}
                ref={mediaElement}
                pip={false}
                playbackRate={playbackRate || 1}
                config={
                    {
                        forceAudio: true,
                        attributes: {
                            controlsList: "nodownload",
                            playsInline: true,
                            autoPlay: !!state?.nostart ? false : true
                        },
                        tracks: [],


                    }} />
        </div>
    </div>
}
export default ImageSoundPlayer;